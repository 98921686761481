<template>
    <div class="pd20x">
        <a-modal
            title="增加有效期"
            :visible="visible"
            @cancel="visible = false; addTime = undefined"
            centered
            destroyOnClose
            :footer="null"
            width="400"
            >
            <div class="taC">
                <a-space>
                    <a-select  style="width: 200px" v-model="addTime" placeholder="请选择有效期">
                        <a-select-option :value="6"> 6个月 </a-select-option>
                        <a-select-option :value="12"> 12个月 </a-select-option>
                    </a-select>
                    <a-button type="primary" @click="handleTime">确定</a-button>
                </a-space>
            </div>
        </a-modal>
        <div class="mb20x">
            <a-button type="primary" class="mr20px" @click="$router.push('/exam/students')"><a-icon type="left" />返回</a-button>
            <a-button type="primary" @click="showCate()">添加科目</a-button>
        </div>
        <a-table
          :columns="columns"
          :data-source="list"
          :rowKey="'id'"
          bordered
          class="normal"
        >
            <template slot="type1" slot-scope="text, record">
                {{record.lh_is_ff?text?formatDay(text*1000):'未开通':'免费'}}
                <a-icon type="plus-circle" v-if="record.lh_is_ff && text" title="增加有效期" class="plusIcon" @click="showTime()"/>
            </template>
            <template slot="type2" slot-scope="text, record">
                {{record.zt_is_ff?text?formatDay(text*1000):'未开通':'免费'}}
                 <a-icon type="plus-circle" v-if="record.zt_is_ff && text" title="增加有效期" class="plusIcon" @click="showTime()"/>
            </template>
            <template slot="type3" slot-scope="text, record">
                {{record.st_is_ff?text?formatDay(text*1000):'未开通':'免费'}}
                <a-icon type="plus-circle" v-if="record.st_is_ff && text" title="增加有效期" class="plusIcon" @click="showTime()"/>
            </template>
            <template slot="type4" slot-scope="text, record">
                {{record.bg_is_ff?text?formatDay(text*1000):'未开通':'免费'}}
            </template>
            <template slot="time" slot-scope="text">
                {{text?formatDay(text*1000):'--'}}
            </template>
            <template slot="operation">
                <!-- <a-space>
                    <a-button type="primary" @click="showCate(record)">编辑</a-button>
                    <a-button type="danger">删除</a-button>
                </a-space> -->
            </template>
        </a-table>
        <a-modal v-model="show" title="选择科目" width="800px" @ok="submit" @cancel="show = false">
            <div class="limit">
                <h3 class="title">
                    <span class="el-tag"></span>科目
                </h3>
            </div>
            <div class="ml20x mb20x">
                <a-cascader ref="cascader" v-model="selectSubject" @change="changeSubject"  style="width:80%" :options="subjectList" :allowClear="false" :fieldNames="{label:'cname',value:'id', children:'son'}" placeholder="请选择科目"/>
            </div>
            <div class="limit">
                <h3 class="title">
                    <span class="el-tag"></span>版块
                </h3>
            </div>
            <div class="ml20x mb20x">
                 <a-spin :indicator="indicator" tip="请先选择科目" :spinning="!selectSubject.length">
                    <a-table
                        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                        :columns="subjectColumns"
                        :data-source="data"
                        :rowKey="'id'"
                        :pagination="false"
                    >
                        <template slot="time" slot-scope="text,record">
                            <div v-if="record.id !=4">
                                <span v-if="record.ff">{{text}}个月</span>
                                <!-- <a-input-number  :defaultValue="text" @change="(val)=>{changeTime(val, record.id)}" style="width:80px"/> -->
                                <span v-else>免费</span>
                            </div>
                            <div v-else>
                                <a-date-picker v-if="record.ff" :disabledDate="disabledDate" style="width:150px" :defaultValue="text"  @change="(val)=>{changeTime(val, record.id)}"/>
                                <span v-else>免费</span>
                            </div>
                        </template>
                    </a-table>
                 </a-spin>
            </div>
        </a-modal>
    </div>
</template>
<script>
const columns = [
    { title: '科目名称', dataIndex: 'cname', align: 'center' }, 
    {
        title: '有效期',
        children: [
            { title: '领航助学', dataIndex: 'type_1_end', align: 'center', scopedSlots: { customRender: 'type1' },}, 
            { title: '真题汇编', dataIndex: 'type_2_end', align: 'center', scopedSlots: { customRender: 'type2' }, }, 
            { title: '十套通关', dataIndex: 'type_3_end', align: 'center', scopedSlots: { customRender: 'type3' }, }, 
            { title: '斩题密训', dataIndex: 'type_4_end', align: 'center', scopedSlots: { customRender: 'type4' }, }, 
        ]
    },
    { title: '状态', dataIndex: 'operation', scopedSlots: { customRender: 'operation' }, align: 'center' }
]
const subjectColumns =[
    { title: '版块名', dataIndex: 'label', align: 'center' }, 
    { title: '有效期', dataIndex: 'time', align: 'center',scopedSlots: { customRender: 'time' } }, 
]
const defaultData = [
    { label: '领航助学', id: 1, time: 6, ff: 0 },
    { label: '真题汇编', id: 2, time: 12, ff: 0 },
    { label: '十套通关', id: 3, time: 6,  ff: 0  },
    { label: '斩题密训', id: 4, time: null,  ff: 0 },
]
import { classB } from '@/libs/transform'
import { getclasses } from '@/libs/api'
import { bindUserCate, getUserCateList, getBasicSet } from '@/libs/examapi'
import {momentDays, formatDay} from '@/libs/moment';
export default {
    name: 'courseLimit',
    data(){
        return{
            //表格
            columns,
            list: [],


            classB,
            subjectList:[],
            platSubjectList:[],
            show: false,
            data: JSON.parse(JSON.stringify(defaultData)),
            checkedBoard: [1,2,3,4],
            currentItem:{},
            checkedList:[],

            selectSubject:[],
            subjectColumns,
            selectedRowKeys: [1,2,3,4],
            spinning:true,
            indicator: null,
            uid: null,

            visible: false,
            addTime: undefined
        }
    },
    
    mounted(){
        this.uid = this.$route.query.uid
        this.init()
    },
    methods:{
        formatDay,
        disabledDate(current) {
            return current && current < momentDays().endOf('day');
        },
        handleOk(){
            this.show = false
        },
        init(){
            getclasses({id: 0}).then(res=>{
               this.subjectList = res
               this.platData(res)
            },error=>{
            })
            this.getData()
        },
        platData(data){
            data.forEach(el =>{
                this.platSubjectList.push(el)
                if(el.son.length){
                    this.platData(el.son)
                }
            })
        },
        getData(){
            getUserCateList({uid: this.uid}).then(res =>{
                this.list = res
            })
        },
        onClose(){
            this.$emit('close')
        },
        changeTime(val, id){
           this.data.forEach(el =>{
               if(el.id == 4){
                   el.time = formatDay(val)
               }else{
                    if(el.id === id){
                        el.time = val
                    }
               }
              
           })
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        submit(){
            if(this.selectedRowKeys.length){
                this.updateData()
            }else{
                this.show = false
            }
        },
        updateData(){
            console.log(this.selectedRowKeys)
            let cate_id = [...this.selectSubject].pop()
            let request = {
                cate_id,
                uid: this.uid,
                type_1_end: this.selectedRowKeys.findIndex(el=>el == 1)>-1?6:0,
                type_2_end: this.selectedRowKeys.findIndex(el=>el == 2)>-1?12:0,
                type_3_end: this.selectedRowKeys.findIndex(el=>el == 3)>-1?6:0,
                type_4_end: this.selectedRowKeys.findIndex(el=>el == 4)>-1?this.data[3].time:0,
            }
            bindUserCate(request).then(res => {
                this.show = false
                this.getData()
            }).catch(() =>{
                this.show = false
            })
        },
        showCate(data){
            this.show = true
            if(data){
                let { cate_id, type_1_end, type_2_end, type_3_end, type_4_end } = data
                this.searchCateId(cate_id)
                this.data = [
                    { label: '领航助学', id: 1, time: type_1_end },
                    { label: '真题汇编', id: 2, time: type_2_end },
                    { label: '十套通关', id: 3, time: type_3_end },
                    { label: '斩题密训', id: 4, time: type_4_end },
                ]
            }else{
                this.selectSubject = []
                this.data = JSON.parse(JSON.stringify(defaultData))
            }
        },
        searchCateId(id){
            this.selectSubject.unshift(id)
            let {parent_id} = this.platSubjectList.filter(el => el.id == id)[0]
            let selected = this.platSubjectList.filter(el => el.id == parent_id)[0]
            if(selected.level>0){
                this.searchCateId(selected.id)
            }else{
                this.selectSubject.unshift(selected.id)
            }
        },
        changeSubject(val){
            let cate_id = [...val].pop()
            getBasicSet({cate_id}).then(res =>{
                let {zt_is_ff, lh_is_ff, st_is_ff, bg_id_ff} = res
                this.data.forEach(el =>{
                    if(el.id ==1){
                        el.ff = lh_is_ff
                    }else if(el.id ==2){
                        el.ff = zt_is_ff
                    }else if(el.id ==3){
                        el.ff = st_is_ff
                    }else{
                        el.ff = bg_id_ff
                    }

                })
            })
        },
        showTime(){
            this.visible = true
        },
        handleTime(){
            this.$message.info('接口等待')
        }
    }
}
</script>
<style lang="less" scoped>
.limit{
    .title{
        font-size: 16px;
        color: #333;
        font-weight: 400;
        line-height: 18px;
        display: flex;
        margin-bottom: 16px
    }
    .el-tag{
        padding: 0 1px;
        height: 18px;
        margin-right: 10px;
        border-width: 1px;
        border-style: solid;
        border-radius: 4px;
        border-color: #409eff;
        background: #409eff;
        box-sizing: border-box
    }
}
.ml10{
    margin-left: 10px;
}
.tags{
    background-color: #f4f4f5;
    border-color: #e9e9eb;
    display: inline-block;
    padding: 0 10px;
    font-size: 12px;
    color: #333;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
    cursor: pointer;
    margin: 0 4px 16px 14px;
    height: 28px;
    line-height: 26px;
}
.tags.is-hit{
    border-color: #409eff;
    background-color: #409eff;
    color: #fff;
}
.plusIcon{
    margin-left: 10px;
    font-size: 20px;
    color: #409eff;
    vertical-align: middle;
    cursor: pointer;
}
</style>