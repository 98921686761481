
export const taskType = (val, small = false) => {
    let arr = [
        { val:'rhythm',name:'节奏' },
        { val:'pitch',name:'音高' },
        { val:'keys_scales',name:'调性和音阶' },
        { val:'intervals',name:'音程' },
        { val:'chords',name:'和弦' },
        { val:'terms_signs_instruments',name:'术语、记号和乐器' },
        { val:'music_in_context',name:'乐谱综合' },
    ]
    if(val){
        return arr.filter(el=>el.val == val)[0].name || arr.filter(el=>el.name == val).val
    }else{
        if(small){
            return [
                { val:'single_choice',name:'单项选择题' },
                { val:'multiple_choice',name:'多项选择题' },
                { val:'jud_question',name:'判断题' },
                { val:'fill',name:'填空题' },
                { val:'answer',name:'简答题' },
            ]
        }else{
            return arr
        }
    }
}

export const choiceType = [
    {
        name: '单选-文字',
        id:1
    },{
        name:'单选-图片',
        id:2
    },{
        name:'多项单选',
        id:3
    },{
        name:'单线拖拽',
        id:4
    },{
        name:'多音拖拽',
        id:5
    },{
        name:'多项选择',
        id:6
    },{
        name:'热点选择',
        id:7
    },{
        name:'填空-通用',
        id:11
    }
]

export const classB = [
    {
        name: '升学历',
        id:1
    },{
        name:'考证书',
        id:2
    },{
        name:'其他类',
        id:3
    }
]

export const num_zn=['一','二','三','四','五','六','七','八','九','十','十一','十二','十三','十四']

